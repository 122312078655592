import React from 'react';
import {Col, Row} from 'react-styled-flexboxgrid';


const Capabilities = () =>
  <section className="about">
    <Row>
      <Col sm={4} xs={12}>
        <p>
          Daletra is a display slab serif font. Unicase. Twirly. A vernacular Galician letterform on drugs. The very typical pencil doodle that ended up as a font.
        </p>
        <p>Suitable for: packaging, posters, lettersigns, postcards, souvenirs, memes &c.</p>
        <p>Daletra colle o seu nome da revista sobre tipografía que formou parte do <a href="http://www.escolarte.com/?portfolio=daletra">TFE de Xavier Álvarez Franco</a>.</p>
        <p>☞ <a class="license" href="https://gumroad.com/l/daletra">License Daletra</a></p>
        <p>☞ <a href="/docs/daletra-type-specimen.pdf">Specimen [PDF 965KB]</a></p>
        <p>☞ <a href="/docs/LICENSE.pdf">Read the EULA</a></p>
      </Col>
      <Col sm={7} smOffset={1} xs={12}>
        <p>Language support:</p>
        <p>Afrikaans, Albanian, Asu, Basque, Bemba, Bena, Bosnian, Catalan, Cebuano, Chiga, Colognian, Cornish, Corsican, Croatian, Czech, Danish, Dutch, English, Estonian, Faroese, Filipino, Finnish, French, Friulian, Galician, Ganda, German, Gusii, Hungarian, Icelandic, Ido, Inari Sami, Indonesian, Interlingua, Irish, Italian, Javanese, Jju, Jola-Fonyi, Kabuverdianu, Kalaallisut, Kalenjin, Kinyarwanda, Kurdish, Latvian, Lithuanian, Lojban, Low German, Lower Sorbian, Luo, Luxembourgish, Luyia, Machame, Makhuwa-Meetto, Makonde, Malagasy, Malay, Maltese, Manx, Maori, Morisyen, North Ndebele, Northern Sami, Northern Sotho, Norwegian Bokmål, Norwegian Nynorsk, Nyanja, Nyankole, Occitan, Oromo, Polish, Portuguese, Romanian, Romansh, Rombo, Rundi, Rwa, Samburu, Sango, Sangu, Sardinian, Scottish Gaelic, Sena, Shambala, Shona, Slovak, Slovenian, Soga, Somali, South Ndebele, Southern Sotho, Spanish, Swahili, Swati, Swedish, Swiss German, Taita, Taroko, Teso, Tsonga, Tswana, Turkish, Turkmen, Upper Sorbian, Vunjo, Walloon, Welsh, Western Frisian, Wolof, Xhosa and Zulu.</p>
      </Col>
    </Row>
  </section>

export default Capabilities;
