import React, { useState } from "react"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const Introduction = () => {
  const [punch, setPunch] = useState("6");
  const [spacing, setSpacing] = useState("0");
  const [draft] = useState("Daletra, outra fonte designed by o pesado de Rubén Prol. Edit this text. Just click and start writing.");

  return (
    <div className="sampler">
      <div className="sampler__controls">
        <div className="controls-slider">
          <div className="controls-label">
            Size
          </div>
          <input
            className="range-slider"
            type="range"
            value={punch}
            min={2}
            max={14}
            onChange={function (event) {
              setPunch(event.target.valueAsNumber)
            }}
          />
        </div>

        <div className="controls-slider">
          <div className="controls-label">
            Letter spacing
          </div>
          <input
            className="range-slider"
            type="range"
            value={spacing}
            min={-8}
            max={8}
            onChange={function (event) {
              setSpacing(event.target.valueAsNumber)
            }}
          />
        </div>
        <div className="controls-slider">
          <ThemeToggler>
            {({ theme, toggleTheme }) => {
              // Don't render anything at compile time. Deferring rendering until we
              // know which theme to use on the client avoids incorrect initial
              // state being displayed.
              if (theme == null) {
                return null
              }
              return (
                <div className="toggle">
                  <span className="dark">☾</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        toggleTheme(e.target.checked ? 'dark' : 'light')
                      }
                      checked={theme === 'dark'}
                    />{' '}
                    <div className="slider round"></div>
                  </label>
                  <span className="light">☀</span>
                </div>
              )
            }}
          </ThemeToggler>
        </div>
      </div>
      <div className="sampler__text" contentEditable style={{ fontSize: `${punch}vw`, letterSpacing: `${spacing}px` }}>
        {draft}
      </div>
    </div>
  )
}


export default Introduction;
