import React from 'react';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';


import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';

import Introduction from '../components/introduction/Introduction';
import Capabilities from '../components/capabilities/Capabilities';

const IndexPage = () =>
  <Layout>
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <Introduction />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Capabilities />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Footer />
        </Col>
      </Row>
    </Grid>
  </Layout>

export default IndexPage;
